interface UseScreen {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  "2xl": boolean;
  "3xl": boolean;
}

interface UseScreenSizes {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  "3xl": string;
}

export const useScreen: UseScreen = reactive({
  sm: false,
  md: false,
  lg: false,
  xl: false,
  "2xl": false,
  "3xl": false,
});

const screens: UseScreenSizes = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
  "3xl": "1921px",
};

export function updateUseScreen() {
  Object.keys(screens).forEach((key) => {
    useScreen[key] = window.matchMedia(`(min-width: ${screens[key]})`).matches;
  });
}

if (process.client) {
  updateUseScreen();
}

export function isBoundingBottom(
  el: HTMLElement | HTMLInputElement | undefined
) {
  if (el) {
    const { bottom } = el.getBoundingClientRect();
    const { innerHeight } = window;
    if (bottom >= innerHeight) {
      return true;
    }
  }
  return false;
}

export function isFullVisible(el: HTMLElement | HTMLInputElement | undefined) {
  if (el) {
    const { bottom, top } = el.getBoundingClientRect();
    const { innerHeight } = window;
    if (top >= 0 && bottom < (innerHeight || el.clientHeight)) {
      return true;
    }
  }
  return false;
}
